/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/DoorofHope-hero.jpg";
// import socialHead2 from "../../../images/Banner-Naccho.jpg";
// import inline from "../../../images/image-nurse-baby.jpg";
import inline from "../../../images/Door-of-hope.png";
import leftIcon from "../../../images/Left Button.svg";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="Door of Hope | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="Door of Hope "
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "Door of Hope is a non-profit organisation saving abandoned babies by finding them safe homes through uniting families, adoption or the Door of Hope Village.  ",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "Save Abandoned Babies via their “baby boxes” and other network relationships. These include babies coming to them through: ",
              },
            ],
            type: "paragraph",
          },
          // {
          //   children: [
          //     {
          //       text: "One of the biggest obstacles faced in today’s world is extreme energy poverty; one of the worst and most unknown forms of poverty. It is a complex challenge, impacting the health, wellbeing, educational outcomes, economic stability and environment of the communities experiencing it. ",
          //     },
          //   ],
          //   type: "paragraph",
          // },
        ]}
        statements={[
          {
            id: "",
            title: "‘Option counselling’ with mothers  ",
            heading: [],
          },
          {
            id: "",
            title: "Partnerships with the police ",
            heading: [],
          },
          {
            id: "",
            title: "Various hospitals where children are abandoned",
            heading: [],
          },
        ]}
        butlletdesign="YES"
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        video="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "Many newborns in South Africa are abandoned by their mothers each year due to poverty, rape and abuse. Door of Hope is well known, having a large online following with many supporters. The challenge they faced was to convert these supporters into a consistent donor base. Due to sporadic donations, the organisations had a lack of planning and monthly budgets. They struggled to define the donor journey, meaning they lacked visibility on the effectiveness of donor interactions and had no clear route on how to communicate with their existing donors. As part of our Corporate Social Investment and to make a social impact, we were engaged to map out the donor journey. ",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
        //     heading: [],
        //   },
        // ]}
        heading={[
          {
            children: [
              {
                text: "Many newborns in South Africa are abandoned by their mothers each year due to poverty, rape and abuse. Door of Hope is well known, having a large online following with many supporters. The challenge they faced was to convert these supporters into a consistent donor base. Due to sporadic donations, the organisations had a lack of planning and monthly budgets. They struggled to define the donor journey, meaning they lacked visibility on the effectiveness of donor interactions and had no clear route on how to communicate with their existing donors. As part of our Corporate Social Investment and to make a social impact, we were engaged to map out the donor journey. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Challenge faced by organisation "
      />
      {/* <ValuesCollection
        key=""
        title="Challenge faced by organisation "
        changeDesign="YES"
        styleclass="pt-5px"
        heading={[
          {
            children: [
              {
                text: "Telehealth can deliver significant benefits including increased access to remote communities, extended capacity and reach of community-based care, and increased efficiency and decreased travel time for patients and providers. However, reliable connectivity, and digital literacy and capability is critical to ensuring successful delivery and uptake of Telehealth services. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
            heading: [],
          },
          {
            id: "",
            title:
              "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
            heading: [],
          },
        ]}
      /> */}
      <ValuesCollection
        key=""
        title="What we did"
        className="bg-light-blue pt-5"
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        statements={[
          {
            id: "",
            title:
              "Identified and addressed pain points in the donor journey and management systems  ",
            heading: [],
          },
          {
            id: "",
            title:
              "Developed the baby house to rebuild fundraising plan and project plan ",
            heading: [],
          },
          {
            id: "",
            title: "Helped them understand their donor target market ",
            heading: [],
          },
          {
            id: "",
            title: "Developed their corporate fundraising 'Pitch Pack' ",
            heading: [],
          },
          {
            id: "",
            title:
              "Completed a donor management software comparison allowing the client to purchase a cost-effective tool to facilitate the donor journey processes ",
            heading: [],
          },
          {
            id: "",
            title:
              "Enhanced their social media strategies and data analysis capabilities",
            heading: [],
          },
          {
            id: "",
            title:
              "Additional fundraise by the Partners in Performance team in Africa as part of the Superhero Challenge in 2021 ",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "Impacted 1000 lives – working off a 1:5 ratio, 5 people are directly impacted by donation of a light, lasting for 10 years (which contributes to 1.46 million extra study hours )",
              },
            ],
            type: "heading-three",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "A prioritised list of initiatives to increase funding by 125% ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Fully mapped out donor journey where all interactions with current and potential donors are clearly defined",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Roadmap with action plans on how to unlock possible donation opportunities ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Increased ease of payment for donors on social media sites through a mobile payment application",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Software comparison that was cost-effective and would facilitate the donor journey",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Additional R146,000 (approximately US$9,200) donations raised under the internal fundraising event",
        //     heading: [],
        //   },
        // ]}
        listing={[
          "A prioritised list of initiatives to increase funding by 125% ",
          "Fully mapped out donor journey where all interactions with current and potential donors are clearly defined",
          "Roadmap with action plans on how to unlock possible donation opportunities ",
          "Increased ease of payment for donors on social media sites through a mobile payment application",
          "Software comparison that was cost-effective and would facilitate the donor journey",
          "Additional R146,000 (approximately US$9,200) donations raised under the internal fundraising event",
        ]}
        Articletitle="Impact achieved"
      />

      {/* <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        butlletdesign="YES"
        // heading={[
        //   {
        //     children: [
        //       {
        //         text: "Impacted 1000 lives – working off a 1:5 ratio, 5 people are directly impacted by donation of a light, lasting for 10 years (which contributes to 1.46 million extra study hours ",
        //       },
        //     ],
        //     type: "paragraph",
        //   },
        // ]}
        statements={[
          {
            id: "",
            title:
              "A prioritised list of initiatives to increase funding by 125% ",
            heading: [],
          },
          {
            id: "",
            title:
              "Fully mapped out donor journey where all interactions with current and potential donors are clearly defined",
            heading: [],
          },
          {
            id: "",
            title:
              "Roadmap with action plans on how to unlock possible donation opportunities ",
            heading: [],
          },
          {
            id: "",
            title:
              "Increased ease of payment for donors on social media sites through a mobile payment application ",
            heading: [],
          },
          {
            id: "",
            title:
              "Software comparison that was cost-effective and would facilitate the donor journey",
            heading: [],
          },
          {
            id: "",
            title:
              "Additional R146,000 (approximately US$9,200) donations raised under the internal fundraising event ",
            heading: [],
          },
        ]}
      /> */}
      <p className="col-span-12 col-start-2 text-center p-20px ga-mt--30">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href=" https://doorofhope.co.za/" className="text-dark-blue">
            https://doorofhope.co.za/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
